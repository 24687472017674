/* sample上ではdropDown.cssとして分けられていなく、index.cssにひとまとめになっていたので、dropDown関係のみ抜き出し */

.dropdown {
  /* modalのz-indexが60なので、それより上に表示されるように70にする */
  z-index: 70;
  display: block;
  position: fixed;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-height: 40px;
  @apply bg-crew-white  dark:bg-crew-gray-900;
}

.dropdown .item {
  margin: 0 8px 0 8px;
  padding: 8px;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  border-radius: 8px;
  border: 0;
  max-width: 250px;
  min-width: 100px;
  @apply bg-crew-white dark:bg-crew-gray-900 text-crew-gray-900 dark:text-crew-gray-50;
}

.dropdown .item.fontsize-item,
.dropdown .item.fontsize-item .text {
  min-width: unset;
}

.dropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.dropdown .item:first-child {
  margin-top: 8px;
}

.dropdown .item:last-child {
  margin-bottom: 8px;
}

.dropdown .item:hover {
  @apply bg-crew-gray-200 dark:bg-crew-gray-700;
}

.dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}

.dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.dropdown .divider {
  width: auto;
  margin: 4px 8px;
  height: 1px;
  @apply bg-crew-white dark:bg-crew-gray-900;
}

@media screen and (max-width: 1100px) {
  .dropdown-button-text {
    display: none !important;
  }
  .font-size .dropdown-button-text {
    display: flex !important;
  }
  .code-language .dropdown-button-text {
    display: flex !important;
  }
}

button.item.dropdown-item-active {
  @apply bg-crew-gray-200 dark:bg-crew-gray-700;
}

button.item.dropdown-item-active i {
  opacity: 1;
}
