@tailwind base;
@tailwind components;
@tailwind utilities;

/* link ------------------------------------------- */
.crew-link {
  @apply text-crew-blue-500 hover:text-crew-blue-400 dark:text-crew-blue-400 dark:hover:text-crew-blue-300 cursor-pointer;
}
/* custom link with color text is gray ------------------- */
.crew-link-gray {
  @apply text-crew-gray-400 hover:text-crew-gray-500 dark:text-crew-gray-500 dark:hover:text-crew-gray-400 cursor-pointer;
}

/* avatar ------------------------------------------- */
.crew-avatar {
  @apply h-10 w-10 flex-shrink-0;
}

.crew-avatar-3xs {
  @apply h-4 w-4 flex-shrink-0;
}

.crew-avatar-2xs {
  @apply h-5 w-5 flex-shrink-0;
}

.crew-avatar-xs {
  @apply h-6 w-6 flex-shrink-0;
}

.crew-avatar-sm {
  @apply h-8 w-8 flex-shrink-0;
}

.crew-avatar-lg {
  @apply h-12 w-12 flex-shrink-0;
}

.crew-avatar-xl {
  @apply h-16 w-16 flex-shrink-0;
}

.crew-avatar-2xl {
  @apply h-28 w-28 flex-shrink-0;
}

/* mention ------------------------------------------- */
.crew-mention-me {
  @apply text-crew-red-600 bg-crew-red-100 dark:text-crew-red-600 dark:bg-crew-red-100;
}

.crew-mention-other {
  @apply text-crew-blue-600 bg-crew-blue-100 dark:text-crew-blue-600 dark:bg-crew-blue-100;
}

.crew-mention-all-here {
  @apply text-crew-orange-600 bg-crew-orange-100 dark:text-crew-orange-600 dark:bg-crew-orange-100;
}

/* reaction ------------------------------------------- */
.crew-reaction {
  @apply rounded-lg px-1.5 py-1 cursor-pointer ring-1 ring-inset ring-crew-gray-200 dark:ring-crew-gray-700 hover:bg-crew-gray-200 hover:dark:bg-crew-gray-700;
}

/* table ------------------------------------------- */
.crew-table {
  @apply min-w-full overflow-x-auto;
}

.crew-table thead,
.crew-table thead th.sticky {
  @apply text-crew-gray-500 dark:text-crew-gray-400 bg-crew-gray-100 dark:bg-crew-gray-800;
}

.crew-table thead tr {
}

.crew-table thead th {
  @apply p-1 font-bold truncate cursor-pointer;
}

.crew-table tbody {
  @apply divide-y divide-crew-gray-200 dark:divide-crew-gray-700 bg-crew-white dark:bg-crew-gray-900;
}

.crew-table tbody tr {
  @apply hover:bg-crew-gray-100 hover:dark:bg-crew-gray-800;
}

.crew-table tbody td {
  @apply p-1;
}

/* gray シリーズ ----------------------------------------- */
/* text ------- ----------------------------------------- */
.crew-text-default {
  @apply text-crew-gray-900 dark:text-crew-gray-50;
}

.crew-text-gray-1 {
  @apply text-crew-gray-800 dark:text-crew-gray-100;
}

.crew-text-gray-2 {
  @apply text-crew-gray-700 dark:text-crew-gray-200;
}

.crew-text-gray-3 {
  @apply text-crew-gray-600 dark:text-crew-gray-300;
}

.crew-text-gray-4 {
  @apply text-crew-gray-500 dark:text-crew-gray-400;
}

.crew-text-gray-5 {
  @apply text-crew-gray-400 dark:text-crew-gray-500;
}

/* bg ------------------------------------------------- */
.crew-bg-default {
  @apply bg-crew-white  dark:bg-crew-gray-900;
}

.crew-bg-gray-1 {
  @apply bg-crew-gray-100 dark:bg-crew-gray-800;
}

.crew-bg-gray-2 {
  @apply bg-crew-gray-200 dark:bg-crew-gray-700;
}

.crew-bg-gray-3 {
  @apply bg-crew-gray-300 dark:bg-crew-gray-600;
}

.crew-bg-gray-4 {
  @apply bg-crew-gray-400 dark:bg-crew-gray-500;
}

.crew-bg-gray-5 {
  @apply bg-crew-gray-500 dark:bg-crew-gray-400;
}

/* その他 ------------------------------------------------- */
.crew-border-gray {
  @apply border-crew-gray-200 dark:border-crew-gray-700;
}

.crew-border-yellow {
  @apply border-crew-yellow-300 dark:border-crew-yellow-600;
}

.crew-divide-gray {
  @apply divide-crew-gray-200 dark:divide-crew-gray-700;
}

.crew-ring-gray {
  @apply ring-crew-gray-200 dark:ring-crew-gray-700;
}

.crew-shadow-gray {
  @apply shadow-crew-gray-200/50 dark:shadow-crew-gray-700/50;
}

.crew-hover-gray-1 {
  @apply hover:bg-crew-gray-100 hover:dark:bg-crew-gray-800;
}

.crew-hover-gray-2 {
  @apply hover:bg-crew-gray-200 hover:dark:bg-crew-gray-700;
}

/* button ------------------------------------------- */
.crew-button {
  @apply px-4 py-2 rounded-md cursor-pointer;
}

.crew-button-sm {
  @apply px-2 py-1 rounded-md text-sm cursor-pointer;
}

.crew-button-lg {
  @apply px-2 py-2 rounded-md text-lg cursor-pointer;
}

/* NOTE: layout-Sampleでcrew-button-normal～crew-button-danger は crew-action-normal～crew-action-dangerとして定義 */
/* action ------------------------------------------- */

/* action-base */
.crew-action-base {
  @apply rounded inline-flex flex-row items-center justify-center cursor-pointer disabled:pointer-events-none;
}

/* action-disabled */
.crew-action-disabled {
  @apply disabled:text-crew-gray-900 disabled:dark:text-crew-gray-50 disabled:bg-crew-gray-200 disabled:dark:bg-crew-gray-700 disabled:bg-opacity-50  disabled:dark:bg-opacity-50 disabled:text-opacity-50 disabled:dark:text-opacity-50;
}

/* action-disabled-outlined */
.crew-action-disabled-outlined {
  @apply disabled:text-crew-gray-900 disabled:dark:text-crew-gray-50 disabled:border-crew-gray-200 disabled:dark:border-crew-gray-700 disabled:text-opacity-50 disabled:dark:text-opacity-50 disabled:border-opacity-50 disabled:dark:border-opacity-50;
}

/* action-disabled-text */
.crew-action-disabled-text {
  @apply disabled:text-crew-gray-900 disabled:dark:text-crew-gray-50 disabled:text-opacity-50 disabled:dark:text-opacity-50;
}

/* action-normal */
.crew-action-normal {
  @apply text-crew-gray-900 dark:text-crew-gray-50 bg-crew-gray-200 dark:bg-crew-gray-700 hover:bg-crew-gray-300 hover:dark:bg-crew-gray-600;
  @apply crew-action-disabled;
}

/* action-normal-outlined */
.crew-action-normal-outlined {
  @apply text-crew-gray-900 dark:text-crew-gray-50 bg-transparent border border-crew-gray-200 dark:border-crew-gray-700 hover:bg-crew-gray-100 hover:dark:bg-crew-gray-800/10;
  @apply crew-action-disabled-outlined;
}

/* action-normal-text */
.crew-action-normal-text {
  @apply text-crew-gray-900 dark:text-crew-gray-50 bg-transparent hover:bg-crew-gray-100 hover:dark:bg-crew-gray-800/10;
  @apply crew-action-disabled-text;
}

/* action-success */
.crew-action-success {
  @apply text-crew-green-50 dark:text-crew-green-50 bg-crew-green-500 dark:bg-crew-green-500 hover:bg-crew-green-600 hover:dark:bg-crew-green-600;
  @apply crew-action-disabled;
}

/* action-success-outlined */
.crew-action-success-outlined {
  @apply text-crew-green-500 dark:text-crew-green-500 bg-transparent border border-crew-green-500 hover:bg-crew-green-100 hover:dark:bg-crew-green-800/10;
  @apply crew-action-disabled-outlined;
}

/* action-success-text */
.crew-action-success-text {
  @apply text-crew-green-500 dark:text-crew-green-500 bg-transparent hover:bg-crew-green-100 hover:dark:bg-crew-green-800/10;
  @apply crew-action-disabled-text;
}

/* action-danger */
.crew-action-danger {
  @apply text-crew-red-50 dark:text-crew-red-50 bg-crew-red-500 dark:bg-crew-red-500 hover:bg-crew-red-600 hover:dark:bg-crew-red-600;
  @apply crew-action-disabled;
}

/* action-danger-outlined */
.crew-action-danger-outlined {
  @apply text-crew-red-500 dark:text-crew-red-500 bg-transparent border border-crew-red-500 dark:border-crew-red-500 hover:bg-crew-red-100 hover:dark:bg-crew-red-800/10;
  @apply crew-action-disabled-outlined;
}

/* action-danger-text */
.crew-action-danger-text {
  @apply text-crew-red-500 dark:text-crew-red-500 bg-transparent hover:bg-crew-red-100 hover:dark:bg-crew-red-800/10;
  @apply crew-action-disabled-text;
}

/* action-default */
.crew-action-default {
  @apply text-crew-blue-50 dark:text-crew-blue-50 bg-crew-blue-500 dark:bg-crew-blue-500 hover:bg-crew-blue-600 hover:dark:bg-crew-blue-600;
  @apply crew-action-disabled;
}

/* action-default-outlined */
.crew-action-default-outlined {
  @apply text-crew-blue-500 dark:text-crew-blue-500 bg-transparent border border-crew-blue-500 dark:border-crew-blue-500 hover:bg-crew-blue-100 hover:dark:bg-crew-blue-800/10;
  @apply crew-action-disabled-outlined;
}

/* action-default-text */
.crew-action-default-text {
  @apply text-crew-blue-500 dark:text-crew-blue-500 bg-transparent hover:bg-crew-blue-100 hover:dark:bg-crew-blue-800/10;
  @apply crew-action-disabled-text;
}

/* badge ------------------------------------------------- */
.crew-badge {
  @apply inline-block rounded-full px-2 py-1 font-bold;
}

.crew-badge-ring {
  @apply ring-1 ring-inset text-crew-gray-700 ring-crew-gray-200 dark:text-crew-gray-200 dark:ring-crew-gray-700;
}

.crew-badge-red {
  @apply text-crew-red-600 bg-crew-red-100 dark:text-crew-red-600 dark:bg-crew-red-100;
}

.crew-badge-red-inverted {
  @apply text-crew-white bg-crew-red-500 dark:text-crew-white dark:bg-crew-red-600;
}

.crew-badge-orange {
  @apply text-crew-orange-600 bg-crew-orange-100 dark:text-crew-orange-600 dark:bg-crew-orange-100;
}

.crew-badge-orange-inverted {
  @apply text-crew-white bg-crew-orange-500 dark:text-crew-white dark:bg-crew-orange-600;
}

.crew-badge-yellow {
  @apply text-crew-yellow-600 bg-crew-yellow-100 dark:text-crew-yellow-600 dark:bg-crew-yellow-100;
}

.crew-badge-yellow-inverted {
  @apply text-crew-white bg-crew-yellow-500 dark:text-crew-white dark:bg-crew-yellow-500;
}

.crew-badge-green {
  @apply text-crew-green-600 bg-crew-green-100 dark:text-crew-green-600 dark:bg-crew-green-100;
}

.crew-badge-green-inverted {
  @apply text-crew-white bg-crew-green-500 dark:text-crew-white dark:bg-crew-green-600;
}

.crew-badge-blue {
  @apply text-crew-blue-600 bg-crew-blue-100 dark:text-crew-blue-600 dark:bg-crew-blue-100;
}

.crew-badge-blue-inverted {
  @apply text-crew-white bg-crew-blue-500 dark:text-crew-white dark:bg-crew-blue-600;
}

.crew-badge-cyan {
  @apply text-crew-cyan-600 bg-crew-cyan-100 dark:text-crew-cyan-600 dark:bg-crew-cyan-100;
}

.crew-badge-cyan-inverted {
  @apply text-crew-white bg-crew-cyan-500 dark:text-crew-white dark:bg-crew-cyan-600;
}

.crew-badge-purple {
  @apply text-crew-purple-600 bg-crew-purple-100 dark:text-crew-purple-600 dark:bg-crew-purple-100;
}

.crew-badge-purple-inverted {
  @apply text-crew-white bg-crew-purple-500 dark:text-crew-white dark:bg-crew-purple-600;
}

.crew-badge-gray {
  @apply text-crew-gray-600 bg-crew-gray-100 dark:text-crew-gray-600 dark:bg-crew-gray-100;
}

.crew-badge-gray-inverted {
  @apply text-crew-white bg-crew-gray-500 dark:text-crew-white dark:bg-crew-gray-600;
}

/* その他 ------------------------------------------------------ */
.crew-error-text {
  @apply text-crew-red-3-light;
  @apply dark:text-crew-red-2-dark;
}

/*-------bage icon priority-----------------------------------------*/
.crew-badge-icon-red {
  @apply text-crew-red-600   dark:text-crew-red-600;
}

.crew-badge-icon-blue {
  @apply text-crew-blue-600  dark:text-crew-blue-600;
}

.crew-badge-icon-green {
  @apply text-crew-green-600  dark:text-crew-green-600;
}

.crew-warning-text {
  @apply text-crew-yellow-600;
  @apply dark:text-crew-yellow-600;
}

/**
 * アテンションやフィードのメッセージごとの未読バッジ用クラス
 */
.crew-badge-unread-message {
  @apply text-crew-red-3-light dark:text-crew-red-2-dark;
}

/**
 * TailwindCSSで指定されているFirefox向けのフォーカスリング設定を取り消す
 *
 * Tailwindでは、フォーカスリングの見た目をブラウザ間で揃えるため、Firefoxに対してスタイルが指定されている。
 * https://github.com/tailwindlabs/tailwindcss/blob/master/src/css/preflight.css#L210
 * また、CrewではDevExtremeを使用しているが、これはフォーカスリングを自前で描画しているため、ブラウザが表示するフォーカスリングを非表示にしている。
 * しかし、Crewの構造上スタイルシートの優先順位が ブラウザ組込 < DevExtreme < Tailwind となっているため、
 * Firefoxで表示すると、DevExtremeが無効化したフォーカスリングがTailWindによって再度有効化されてしまう。
 * 結果、Firefoxでは不要なフォーカスリングが表示され、表示が崩れてしまう。
 *
 * フォーカスリングはDevExtremeが描画しており、ブラウザ標準のスタイルを揃える必要はないので、Tailwindの設定を取り消す。
 */
:-moz-focusring {
  outline: initial;
}
