/* スクロールバー関連 */
*::-webkit-scrollbar {
  width: 0.7em;
  height: 0.7em;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*:hover::-webkit-scrollbar-thumb {
  background-color: #a0a0a0;
  border-radius: 1em;
}

*::-webkit-scrollbar-thumb {
  border-right: #a0a0a0 solid 3px;
  background: transparent;
}

*::-webkit-scrollbar-corner {
  background: transparent;
}

.is-firefox div {
  scrollbar-color: #a0a0a0 #0000;
  scrollbar-width: thin;
}

/* custom dialog toolbar label overflow width */
.dx-toolbar-label .dx-toolbar-item-content,
.dx-toolbar-label .dx-toolbar-item-content > div {
  overflow: visible;
}

.list li li {
  margin-left: 1em;
}

/* 数値リストの1段目 */
.list > ol {
  list-style: none;
  counter-reset: counter;
}

.list > ol > li::before {
  content: counter(counter) '. ';
  counter-increment: counter;
}

/* 2段目 */
.list > ol > li > ol {
  list-style: none;
  counter-reset: counter2;
}

.list > ol > li > ol > li::before {
  content: counter(counter2, lower-alpha) '. ';
  counter-increment: counter2;
}

/* 3段目 */
.list > ol > li > ol > li > ol {
  list-style: none;
  counter-reset: counter3;
}

.list > ol > li > ol > li > ol > li::before {
  content: counter(counter3, lower-roman) '. ';
  counter-increment: counter3;
}

/* 4段目 */
.list > ol > li > ol > li > ol > li > ol {
  list-style: none;
  counter-reset: counter4;
}

.list > ol > li > ol > li > ol > li > ol > li::before {
  content: counter(counter4) '. ';
  counter-increment: counter4;
}

/* 5段目 */
.list > ol > li > ol > li > ol > li > ol > li > ol {
  list-style: none;
  counter-reset: counter5;
}

.list > ol > li > ol > li > ol > li > ol > li > ol > li::before {
  content: counter(counter5, lower-alpha) '. ';
  counter-increment: counter5;
}

/* 6段目 */
.list > ol > li > ol > li > ol > li > ol > li > ol > li > ol {
  list-style: none;
  counter-reset: counter6;
}

.list > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li::before {
  content: counter(counter6, lower-roman) '. ';
  counter-increment: counter6;
}

/* 7段目 */
.list > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol {
  list-style: none;
  counter-reset: counter7;
}

.list
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li::before {
  content: counter(counter7) '. ';
  counter-increment: counter7;
}

/* 8段目 */
.list
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol {
  list-style: none;
  counter-reset: counter8;
}

.list
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li::before {
  content: counter(counter8, lower-alpha) '. ';
  counter-increment: counter8;
}

/* 9段目 */
.list
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol {
  list-style: none;
  counter-reset: counter9;
}

.list
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li
  > ol
  > li::before {
  content: counter(counter9, lower-roman) '. ';
  counter-increment: counter9;
}

/* htmlエディタのツールボタン スリム化 */
.crew-slim-toolbar-item
  .dx-toolbar-text-auto-hide
  .dx-button
  .dx-button-content {
  padding: 3px;
}

/* htmlエディタのコンテキストメニュー スリム化 */
.crew-slim-toolbar-item .dx-button-has-icon .dx-button-content {
  padding: 3px;
}

/* ヘッダ右側の言語切替・テーマ切替・ログイン情報・テナント設定ボタンのカスタム */
.crew-header-right-buttongroup .dx-button {
  height: 38px;
}

/* ログイン情報ボタンのカスタム
  （上記のcrew-header-right-buttongroupの対応だけだとアイコンとテキストが下に寄ってしまうのでその対処） */
.crew-login-user-button .dx-button-content {
  display: flex;
}

/* dx-button-contentを使用しているボタンのスリム化 */
.crew-slim-button .dx-button-content {
  padding: 2px 6px;
}

/* dx-button-contentを使用しているソート順ボタンのスリム化 */
.crew-slim-sort-button .dx-button-content {
  padding: 2px;
}

/** CrewDataGridのページャーと表示件数ボタンのカスタム **/

/* サイズ */
.crew-datagrid-buttongroup .dx-button-content {
  padding: 2px 6px;
  min-width: 24px;
}

/* CrewAccordionの境界線削除 */
.crew-accordion-without-border .dx-accordion-item-opened {
  border: none;
}
.crew-accordion-without-border .dx-accordion-item:last-child {
  border: none;
}
.crew-accordion-without-border .dx-accordion-item {
  border: none;
}

/* スクロールバー関連 */
*::-webkit-scrollbar {
  width: 0.7em;
  height: 0.7em;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 1em;
  background: transparent;
  border-right: 0 solid transparent;
}

*:hover::-webkit-scrollbar-thumb {
  background-color: theme(colors.crew.gray.200);
}

.dark *:hover::-webkit-scrollbar-thumb {
  background-color: theme(colors.crew.gray.700);
}

*::-webkit-scrollbar-corner {
  background: transparent;
}

/* DevExtremeのカレンダー表示ヘッダのz-indexを初期化で上書き 
   ダイアログの上にヘッダが被る現象の対処 */
.dx-scheduler-header {
  z-index: auto;
}

/* DevExtremeのタイムライン表示ヘッダの幅を上書き */
.dx-scheduler-timeline .dx-scheduler-date-table-cell,
.dx-scheduler-timeline
  .dx-scheduler-header-panel-cell:not(.dx-scheduler-header-panel-week-cell) {
  width: 70px;
}

/* Htmlエディターのツールバードロップダウンメニュー内のSVGアイコンのサイズ指定
   指定なしだと表示のタイミングでSVGのサイズが0になって潰れるときがあるのでその対処 */
.crew-html-editor-toolbar-dropdown-menu svg {
  width: 24px;
  height: 24px;
}

/*style editor when drag and drop */
.editor-dropzone-wrapper .editor-dropzone {
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  width: 100%;
}

/* タスク一覧のカンバン表示の幅を固定
   固定しない場合にカンバン枠が縦ボード枠を超えてしまうための対処 */
.crew-task-list-kanban .dx-scrollable-content {
  width: 100%;
}

/* ポップオーバーの固定余白の幅を修正する */
.dx-popover-wrapper .dx-popup-content {
  padding: 0.5rem;
}

/* custom lighbox slide style */
.lightbox-container video {
  max-height: 100%;
}

/* custom color button mark all read */
.crew-mark-all-read-button .dx-icon {
  color: theme(colors.crew.gray.400);
}

.crew-dropdown-button .dx-list-item {
  display: block;
}

/* custom dx-toast-message pre-line style for line break */
.dx-toast-message {
  white-space: pre-line;
  line-height: 20px;
}

/* height header and row of custom table */
.custom-table thead tr {
  height: 40px;
}
.custom-table tbody tr {
  height: 50px;
}
.custom-table thead tr th,
.custom-table tbody tr td {
  vertical-align: middle !important;
}

/* align right for dx-input */
/* https://supportcenter.devexpress.com/ticket/details/t494096/dxnumberbox-how-to-change-the-placeholder-alignment */
.align-right-input input,
.align-right-input .dx-placeholder {
  text-align: right !important;
}
.align-right-input .dx-placeholder {
  right: 0;
}

/* https://supportcenter.devexpress.com/ticket/details/t422482/dxdatagrid-how-to-show-a-filter-icon-to-the-right-of-the-column-s-caption */
/* gantt header filter icon style */
.filter-icon-alignment-right .dx-column-indicators {
  float: right !important;
}

/* style header edit mode */
#treeListtasks .dx-treelist-headers .dx-treelist-text-content {
  @apply text-crew-gray-900 dark:text-crew-gray-50 font-bold;
}

/* style header disabled mode */
#treeListtasks .disable-cell-editing,
#treeListtasks
  .dx-treelist-headers
  .disable-cell-editing
  .dx-treelist-text-content {
  @apply text-crew-gray-500 dark:text-crew-gray-400;
}

/* custom color menu context folder item */
.crew-folder-menu-context .dx-icon {
  color: theme(colors.crew.gray.900);
}

/* Custom treeview item padding */
#treeview .dx-item.dx-treeview-item {
  padding: 0;
}

/* Custom datagrid style */

#tasksForWorkTimesDataGrid .dx-datagrid-focus-overlay {
  border: 0px;
}

#tasksForWorkTimesDataGrid
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > td {
  vertical-align: middle;
}

#tasksForWorkTimesDataGrid .dx-cell-modified::after {
  border: 0px;
}

#tasksForWorkTimesDataGrid .dx-datagrid-header-panel {
  border: 0px;
}

/* 日本語表記の場合レイアウトが崩れるため、行の高さを調整 */
#gantt-chart .dx-treelist-text-content {
  line-height: 21px;
}

/* Custom scheduler style */
.dx-scheduler-header .dx-toolbar-items-container .dx-item-content {
  padding: 0;
}

.dx-scheduler-header .dx-toolbar-before {
  padding-left: 10px;
}

.dx-scheduler-header .dx-toolbar-after {
  padding-right: 10px;
}

#schedulerGroupMonthly .dx-scheduler-cell-sizes-horizontal,
#schedulerGroupMonthly .dx-scheduler-group-header,
#projectDetailScheduler
  .dx-scheduler-timeline-month
  .dx-scheduler-cell-sizes-horizontal,
#projectDetailScheduler .dx-scheduler-timeline-month .dx-scheduler-group-header,
#taskListTimelineScheduler .dx-scheduler-cell-sizes-horizontal,
#taskListTimelineScheduler .dx-scheduler-group-header {
  width: 200px;
}
#schedulerGroupMonthly .dx-scheduler-header-panel-cell {
  height: 30px;
}
#schedulerGroupMonthly .dx-scheduler-view-switcher,
.custom-scheduler .dx-scheduler-view-switcher {
  display: none;
}

/* TODO: DevExtremeのThemeBuilderCLIで作り直した後に削除すること */
/* https://break-tmc.atlassian.net/browse/CREW-15551 */
/* Display gantt splitter bar */
#gantt-chart .dx-splitter-wrapper .dx-splitter-border .dx-splitter {
  width: 2px;
  background-color: theme(colors.crew.gray.300);
}

/* add scroll for gantt-chart toolbar items */
#gantt-chart .dx-toolbar-items-container {
  overflow-x: auto;
  overflow-y: hidden;
}

/* Custom scheduler as figma */
.custom-scheduler .dx-scheduler-time-panel-cell {
  vertical-align: middle;
}
.custom-scheduler table th,
.custom-scheduler table td,
.custom-scheduler .dx-scheduler-all-day-title,
.custom-scheduler .dx-scheduler-time-panel,
.custom-scheduler .dx-scheduler-header-panel-empty-cell,
.custom-scheduler .dx-scheduler-work-space {
  border-color: theme(colors.crew.gray.400);
}
.custom-scheduler
  .dx-scheduler-time-panel-row:last-child
  .dx-scheduler-time-panel-cell {
  border-bottom-color: transparent;
}
.custom-scheduler .dx-scheduler-header {
  border: none;
  @apply bg-crew-white  dark:bg-crew-gray-900;
}
.custom-scheduler .dx-scheduler-work-space {
  border-top: 1px solid theme(colors.crew.gray.400);
}
.custom-scheduler .dx-scheduler-all-day-table-cell,
.custom-scheduler .dx-scheduler-all-day-title {
  border-bottom-width: 1px;
}
.custom-scheduler .dx-scheduler-header-panel-cell:first-child {
  border-left-color: transparent;
}
.custom-scheduler .dx-scheduler-date-table-row .dx-scheduler-first-group-cell {
  border-left: 1px solid transparent;
}
.custom-scheduler .dx-scheduler-time-panel-cell::after {
  background: theme(colors.crew.gray.400);
}

.custom-scheduler .dx-scheduler-time-panel-cell > div {
  margin-top: 0;
}
.custom-scheduler table th {
  height: 30px;
}
.custom-scheduler .dx-toolbar-before,
.custom-scheduler .dx-toolbar-after {
  padding-left: 0;
  padding-right: 0;
}

/* TODO: DevExtremeのThemeBuilderCLIで作り直した後に削除すること */
/* https://break-tmc.atlassian.net/browse/CREW-15551 */
/* Display scheduler focused state background */
.dx-scheduler-date-table-cell.dx-state-focused {
  background-color: rgba(3, 169, 244, 0.12);
  opacity: 1;
}

/* https://break-tmc.atlassian.net/browse/CREW-17605 */
/* Custom min height table row schedule group monthly */
#schedulerGroupMonthly
  .dx-scheduler-timeline-month
  .dx-scheduler-date-table-row,
#projectDetailScheduler
  .dx-scheduler-timeline-month
  .dx-scheduler-date-table-row {
  min-height: 110px;
}

/* イベント内容を表示するポップオーバーコンポーネントにはスクロールバーがあるため、スクロールバーを削除するために下記のCSSを追加しました。
修正方法について、次のトピックを参照しました。
https://supportcenter.devexpress.com/ticket/details/t812372/scheduler-how-to-make-the-appointment-tooltip-height-change-according-to-the-content */
.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
  max-height: 400px !important;
}
.dx-scheduler-appointment-tooltip-wrapper > div.dx-overlay-content {
  /* Add 10px padding */
  max-height: 410px !important;
}

/* Custom react-doc-viewer scroll */
#proxy-renderer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
  overflow: hidden;
}

#pdf-renderer {
  flex-grow: 1;
  max-height: 100%;
  overflow: hidden;
}

#react-doc-viewer .react-pdf__Document {
  overflow: auto;
  flex-grow: 1;
  max-width: 100%;
  margin: 0;
}

#pdf-page-wrapper {
  margin: 0 auto;
}

/* Hidden pdf controls download button */
#pdf-controls #pdf-download {
  display: none;
}

/* Custom dx-scheduler-appointment-tooltip-wrapper active and focused state */
/*https://break-tmc.atlassian.net/browse/CREW-17596*/
.dx-scheduler-appointment-tooltip-wrapper
  > .dx-popup-normal
  .dx-list-item.dx-state-active {
  background-color: transparent;
}
.dx-scheduler-appointment-tooltip-wrapper
  > .dx-popup-normal
  .dx-list-item.dx-state-focused {
  background-color: transparent;
}

/* 月間カレンダー上の今日の日付が目立つように、枠を太くする */
#projectDetailScheduler .dx-scheduler-date-table-current-date {
  border: 2px solid theme(colors.crew.gray.400);
}

/* Custom scheduler border mode timelineMonth */
.custom-scheduler
  .dx-scheduler-timeline-month
  .dx-scheduler-date-table-row
  .dx-scheduler-first-group-cell {
  border-left: 1px solid theme(colors.crew.gray.400);
}

.custom-scheduler
  .dx-scheduler-timeline-month
  .dx-scheduler-date-table-row
  .dx-scheduler-first-group-cell:first-child {
  border-left: none;
}

/* Because the z-index of the modal is 60, change the z-index of dx-scheduler-date-time-indicator::before to 60. */
/* https://break-tmc.atlassian.net/browse/CREW-17899 */
#taskListTimelineScheduler .dx-scheduler-date-time-indicator::before {
  z-index: 50;
}

/* Set scheduler calendar header full width */
#projectDetailScheduler .dx-scheduler-header {
  width: 100% !important;
}

/* set default timelineMonth cell height */
#schedulerGroupMonthly
  .dx-scheduler-timeline-month
  .dx-scheduler-date-table-row,
#projectDetailScheduler
  .dx-scheduler-timeline-month
  .dx-scheduler-date-table-row {
  height: 115px;
}

/* custom select more appointment default button */
.dx-scheduler .dx-scheduler-appointment-collector {
  background-color: transparent !important;
  box-shadow: none !important;
}
.dx-scheduler .dx-scheduler-appointment-collector.dx-state-hover {
  background-color: transparent !important;
  box-shadow: none !important;
}
.dx-scheduler .dx-scheduler-appointment-collector.dx-state-hover .crew-link {
  text-decoration: underline;
}
.dx-scheduler .dx-scheduler-appointment-collector.dx-state-hover::before {
  background-color: transparent !important;
}

/* set default timelineMonth cell height */
#taskListTimelineScheduler .dx-scheduler-date-table-row {
  height: 115px;
}

/* 全要素でラバーバンドスクロール(iOSでスクロール領域がびよんびよんする奴)を無効化する */
* {
  overscroll-behavior: none;
}
