.emoji-menu {
  width: 250px;
}

.mentions-menu {
  width: 250px;
}

.mention:focus {
  box-shadow: rgb(180 213 255) 0px 0px 0px 2px;
  outline: none;
}

/* modalのz-indexが30なので、それより上に表示されるように35にする */
#typeahead-menu {
  z-index: 35;
}

.typeahead-popover {
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  margin-top: 25px;
}

.typeahead-popover ul {
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 8px;
  max-height: 300px;
  overflow-y: scroll;
}

.typeahead-popover ul::-webkit-scrollbar {
  display: none;
}

.typeahead-popover ul {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.typeahead-popover ul li {
  margin: 0;
  min-width: 180px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
}

.typeahead-popover ul li.selected {
  background: #eee;
}

.typeahead-popover li {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
}

.typeahead-popover li.active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.typeahead-popover li:first-child {
  border-radius: 8px 8px 0px 0px;
}

.typeahead-popover li:last-child {
  border-radius: 0px 0px 8px 8px;
}

.typeahead-popover li:hover {
  background-color: #eee;
}

.typeahead-popover li .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}

.typeahead-popover li .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* editor list  --------------------*/
.CrewEditorTheme__ol1 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-position: inside;
  list-style-type: decimal;
}
.CrewEditorTheme__ol2 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-type: upper-alpha;
  list-style-position: inside;
}
.CrewEditorTheme__ol3 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-type: lower-alpha;
  list-style-position: inside;
}
.CrewEditorTheme__ol4 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-type: upper-roman;
  list-style-position: inside;
}
.CrewEditorTheme__ol5 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-type: lower-roman;
  list-style-position: inside;
}
.CrewEditorTheme__ul {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-position: inside;
  list-style-type: disc;
}
.CrewEditorTheme__listItem {
  margin: 0 5px;
}
.CrewEditorTheme__listItemChecked,
.CrewEditorTheme__listItemUnchecked {
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
  padding-left: 24px;
  padding-right: 24px;
  list-style-type: none;
  outline: none;
}
.CrewEditorTheme__listItemChecked {
  text-decoration: line-through;
}
.CrewEditorTheme__listItemUnchecked:before,
.CrewEditorTheme__listItemChecked:before {
  content: '';
  width: 16px;
  height: 16px;
  top: 50%;
  left: 0;
  cursor: pointer;
  display: block;
  background-size: cover;
  position: absolute;
  transform: translateY(-50%);
}
.CrewEditorTheme__listItemUnchecked[dir='rtl']:before,
.CrewEditorTheme__listItemChecked[dir='rtl']:before {
  left: auto;
  right: 0;
}
.CrewEditorTheme__listItemUnchecked:focus:before,
.CrewEditorTheme__listItemChecked:focus:before {
  box-shadow: 0 0 0 2px #a6cdfe;
  border-radius: 2px;
}
.CrewEditorTheme__listItemUnchecked:before {
  border: 1px solid #999;
  border-radius: 2px;
}
.CrewEditorTheme__listItemChecked:before {
  border: 1px solid rgb(61, 135, 245);
  border-radius: 2px;
  background-color: #3d87f5;
  background-repeat: no-repeat;
}
.CrewEditorTheme__listItemChecked:after {
  content: '';
  cursor: pointer;
  border-color: #fff;
  border-style: solid;
  position: absolute;
  display: block;
  top: 6px;
  width: 3px;
  left: 7px;
  height: 6px;
  transform: rotate(45deg);
  border-width: 0 2px 2px 0;
}
.CrewEditorTheme__nestedListItem {
  list-style-type: none;
}
.CrewEditorTheme__nestedListItem:before,
.CrewEditorTheme__nestedListItem:after {
  display: none;
}

/* Table -----------------------*/

.CrewEditorTheme__table {
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%;
  overflow-y: scroll;
  table-layout: fixed;
  margin: 20px 0;
}
.CrewEditorTheme__tableSelected {
  outline: 2px solid rgb(60, 132, 244);
}
.CrewEditorTheme__tableCell {
  border: 1px solid #bbb;
  min-width: 75px;
  vertical-align: top;
  text-align: start;
  padding: 6px 8px;
  position: relative;
  cursor: default;
  outline: none;
}
.CrewEditorTheme__tableCellSortedIndicator {
  display: block;
  opacity: 0.5;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #999;
}
.CrewEditorTheme__tableCellHeader {
  text-align: start;
  @apply bg-crew-gray-100 dark:bg-crew-gray-800 !important;
}
.CrewEditorTheme__tableCellSelected {
  background-color: #c9dbf0;
}
.CrewEditorTheme__tableCellPrimarySelected {
  border: 2px solid rgb(60, 132, 244);
  display: block;
  height: calc(100% - 2px);
  position: absolute;
  width: calc(100% - 2px);
  left: -1px;
  top: -1px;
  z-index: 2;
}
.CrewEditorTheme__tableCellEditing {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
}
.CrewEditorTheme__tableAddColumns {
  position: absolute;
  top: 0;
  width: 20px;
  background-color: #eee;
  height: 100%;
  right: 0;
  animation: table-controls 0.2s ease;
  border: 0;
  cursor: pointer;
}
.CrewEditorTheme__tableAddColumns:after {
  background-image: url(../../../assets/icons/plus.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
.CrewEditorTheme__tableAddColumns:hover {
  background-color: #c9dbf0;
}
.CrewEditorTheme__tableAddRows {
  position: absolute;
  bottom: -25px;
  width: calc(100% - 25px);
  background-color: #eee;
  height: 20px;
  left: 0;
  animation: table-controls 0.2s ease;
  border: 0;
  cursor: pointer;
}
.CrewEditorTheme__tableAddRows:after {
  background-image: url(../../../assets/icons/plus.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
.CrewEditorTheme__tableAddRows:hover {
  background-color: #c9dbf0;
}
@keyframes table-controls {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.CrewEditorTheme__tableCellActionButtonContainer {
  display: block;
  right: 5px;
  top: 6px;
  position: absolute;
  z-index: 4;
  width: 20px;
  height: 20px;
}
.CrewEditorTheme__tableCellActionButton {
  background-color: #eee;
  display: block;
  border: 0;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  color: #222;
  cursor: pointer;
}
.CrewEditorTheme__tableCellActionButton:hover {
  background-color: #ddd;
}

.TableNode__contentEditable {
  min-height: 20px;
  border: 0px;
  resize: none;
  cursor: text;
  display: block;
  position: relative;
  tab-size: 1;
  outline: 0px;
  padding: 0;
  user-select: text;
  font-size: 15px;
  white-space: pre-wrap;
  word-break: break-word;
  z-index: 3;
}

/* editor link card  --------------------*/
.link-item-shadow {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px 0px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

/* link editor */
.link-editor .link-input {
  display: block;
  width: calc(100% - 75px);
  box-sizing: border-box;
  margin: 12px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  font-size: 15px;
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
  @apply text-crew-gray-900 dark:text-crew-gray-50 bg-crew-white dark:bg-crew-gray-700;
}

.link-editor .link-view {
  display: block;
  width: calc(100% - 24px);
  margin: 8px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  font-size: 15px;
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}

.link-editor .link-view a {
  display: block;
  word-break: break-word;
  width: calc(100% - 33px);
}

.link-editor div.link-edit {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  @apply text-crew-gray-900 dark:text-crew-gray-50;
}

.link-editor div.link-trash {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  @apply text-crew-gray-900 dark:text-crew-gray-50;
}

.link-editor div.link-cancel {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  @apply text-crew-gray-900 dark:text-crew-gray-50;
}

.link-editor div.link-confirm {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  @apply text-crew-gray-900 dark:text-crew-gray-50;
}

.link-editor .link-input a {
  color: rgb(33, 111, 219);
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;
}

.link-editor .link-input a:hover {
  text-decoration: underline;
}

.link-editor .font-size-wrapper,
.link-editor .font-family-wrapper {
  display: flex;
  margin: 0 4px;
}

.link-editor select {
  padding: 6px;
  border: none;
  background-color: rgba(0, 0, 0, 0.075);
  border-radius: 4px;
}

.table-cell-action-button-container {
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
}

/* Preview indent or space in text node */
.crew-html-editor-table span {
  white-space-collapse: preserve;
}

/* Set dark mode for codeblock editor */
html.dark .CrewEditorTheme__code {
  background-color: #1e1e1e;
}
html.dark .CrewEditorTheme__code::before {
  background-color: #1e1e1e;
  border-right: 1px solid #404040;
}
